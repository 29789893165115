import {
    SEND_CONFIGURATION_TO_EMAIL,
    CREATE_CONFIGURATION,
    SAVE_CONFIGURATION_IMAGE,
} from 'api/mutations/configurator';
import { BIKE_MODEL } from 'api/queries/bikeModel';
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { capitalizeFirstLetter } from 'shared/helpers/helpers';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { apolloClient } from 'shared/lib/apollo';
import { areArraysDifferent, daysToMilliseconds, setLSKeyWithExpiry, sortByAttribute } from 'shared/providers/utils';

import {
    BIKE_PRECONFIGURATION,
    BIKE_PRECONFIGURATIONS,
    GET_BIKE_BY_SID,
    PARTS,
    PRODUCT,
    PRODUCT_PART_MODEL,
} from '../api/queries/configurator';
import { Loaded3dItem, OptionsByType, SelectedColorsType, SittingPosition } from '../shared/types/configurator';

class ConfiguratorStore {
    bike: any;
    bikeCategoryId: any = localStorage.getItem(LocalStorageKey.CATEGORY_ID);
    bikeModel: any;
    shareableID = '';
    configurationID = '';

    // If listenForNavigationChange is true when navigating from configurator page confirmation prompt will be shown
    // Change this to false if you want to navigate from configurator page without confirmation prompt
    listenForNavigationChange = true;

    // BIKE MODEL PAGE
    bikeModelPageLoading: boolean = true;
    categoryProducts = [];
    category: any = {
        name: '',
    };
    selectedBike: any;

    // CONFIGURATOR
    initialLoading = false;
    configuratorPageLoading: boolean = true;
    configuratorSizeLoading = false;
    initialParts: any = [];
    preconfigurationId: any;
    preconfigurationChanged: any;
    preconfigurationsList: any;
    configuratorParts: OptionsByType[] | null = [];
    configuratorCategories: any[] = [];
    mandatoryParts: any = [];
    selectedParts: any[] = [];
    sizes = [];
    selectedSize: any = null;
    sittingPositions: SittingPosition[] = [];
    selectedSittingPosition: SittingPosition | any = null;
    selectedBikeVariant: any = null;
    selectedColors: SelectedColorsType[] = [];
    selectedColorType: string = 'glossy';
    modalParts: any = { conflict: [], conflictTypes: [], required: [], requiredTypes: [] };

    // mainImage: string = '';
    sendedEmails: string[] = [];

    // modals
    frameSizeModal = false;
    menuPopupIgnoreClickOutside = false;

    // frame color
    frameColor = null;

    // on select model
    triggerSelect = false;
    selectedProductTypeId = null;

    //screenshot for configuration
    screenshot = '';
    takeScreenshot: any;

    getModelData: null | { productPartModels: any[] } = null;
    threeScene: any = null;

    loadingData: any[] | null = [];
    isFirstLoad = true;
    selectedGroupType = '';
    isOpenOverlayCanvas = false;
    loadingModelIds: Loaded3dItem[] = [];
    isTwinner = false;
    locale = localStorage.getItem(LocalStorageKey.LANGUAGE)?.split('-')[0].toUpperCase() || 'DE';
    exitIntent = true;
    configurationExists = false;

    loadingEnded = false;
    sidebarWidth = 362;
    sidebarExpanded = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setBike = (bike: string) => {
        this.bike = bike;
    };

    setSelectedPart = (
        id: string | number,
        typeId: string | number,
        price: number,
        weight: number,
        variantName: string,
        group: string,
        variantId: string,
        shortName: string,
    ) => {
        const part = this.configuratorParts
            ?.find(item => item.typeId === typeId)
            ?.values.find(v => v.referenceProduct.id.includes(id.toString()));

        const partId = this.getModelData?.productPartModels?.find(i => i.id === part?.referenceProduct.id);

        this.selectedProductTypeId = partId?.productType?.id;
        // check if there is a part with variants already added to the list
        // if it exists, only update that part variant and it's price (only one variant can be selected for specific part item)
        const selectedPartIndex = this.selectedParts.findIndex(p => p.productTypeId === typeId);
        if (selectedPartIndex !== -1) {
            this.selectedParts[selectedPartIndex].id = id;
            this.selectedParts[selectedPartIndex].price = price;
            this.selectedParts[selectedPartIndex].weight = weight || 0;
            this.selectedParts[selectedPartIndex].variantName = variantName;
            this.selectedParts[selectedPartIndex].group = group;
            this.selectedParts[selectedPartIndex].variantId = variantId;
            this.selectedParts[selectedPartIndex].productTypeId = typeId || partId?.productType?.id;
            this.selectedParts[selectedPartIndex].shortName = shortName;
        } else {
            this.selectedParts.push({
                id,
                typeId,
                group,
                price,
                productTypeId: typeId || partId?.productType?.id,
                variantId,
                variantName,
                weight,
                shortName,
            });
        }
        this.getLoadingData();
        this.triggerSelect = !this.triggerSelect;
    };

    removeSelectedPart = (id: string, productType?: string) => {
        this.selectedParts = productType
            ? this.selectedParts.filter(item => item.partName !== productType)
            : this.selectedParts.filter(item => item.id !== id);
        this.triggerSelect = !this.triggerSelect;
        this.selectedProductTypeId = null;
        this.selectedGroupType = '';
    };

    setBikeCategoryId = (id: string) => {
        this.bikeCategoryId = id;
    };

    setPreconfigurationId = (id: string, override: boolean = false) => {
        const areArraysEqual = areArraysDifferent(this.selectedParts, this.initialParts);

        // need this flag first because if user clicks on "YES" the preconfiguration needs to be rendered
        // and array are still not equal
        if (override) {
            this.preconfigurationChanged = '';
            this.preconfigurationId = id;
            this.setPartsFromPreconfiguration(id);
            return (this.initialParts = this.selectedParts);
        }

        if (!areArraysEqual) {
            const selectedPreconfiguration = this.preconfigurationsList.find((preconfig: any) => preconfig.id === id);
            // TODO: extend object when screenshot of preconfigurations is implemented
            return (this.preconfigurationChanged = {
                title: selectedPreconfiguration.title,
                id: selectedPreconfiguration.id,
                prevId: this.preconfigurationId,
            });

            // just change preconfigurations if arrays are equal (standard flow)
        } else {
            this.preconfigurationChanged = '';
            this.preconfigurationId = id;
            this.setPartsFromPreconfiguration(id);
            return (this.initialParts = this.selectedParts);
        }
    };

    // LOAD BIKE CATEGORY
    getBikeCategory = (id: string, channel = process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL) => {
        this.bikeModelPageLoading = true;
        const category = id;
        apolloClient
            .query({
                query: BIKE_MODEL,
                variables: { category, channel, language: this.locale },
            })
            .then(resp => {
                if (resp.data) {
                    this.categoryProducts = resp.data.products.edges.map((p: any) => p.node);
                    this.category = resp.data.collection;
                    this.selectedBike = resp.data.products.edges[0]?.node;
                    this.selectedBikeVariant = {
                        id: resp.data.products.edges[0]?.node?.variants[0]?.id,
                        weight: resp.data.products.edges[0]?.node?.variants[0]?.weight?.value || 0,
                        color:
                            resp.data.products.edges[0]?.node?.variants[0]?.attributes[0]?.values[0]?.value ||
                            '#CCCCCC',
                        colorName: resp.data.products.edges[0]?.node?.variants[0]?.attributes[0]?.values[0]?.name,
                    };
                    this.handleSittingPositions();
                    this.selectedSize = '';
                    this.bikeModelPageLoading = false;
                }
            })
            .catch(e => {
                console.error(e);
                this.bikeModelPageLoading = false;
            })
            .finally(() => {
                this.bikeModelPageLoading = false;
            });
    };

    fetchProduct = (bikeId: string) => {
        const id = bikeId;
        apolloClient
            .query({ query: PRODUCT, variables: { id, language: this.locale } })
            .then(resp => {
                if (resp.data) {
                    this.selectedBike = resp.data.product;
                    this.selectedBikeVariant = {
                        id: resp.data.product?.variants[0]?.id,
                        weight: resp.data.product?.variants[0]?.weight?.value || 0,
                        color: resp.data.product?.variants[0]?.attributes[0]?.values[0]?.value || '#CCCCCC',
                        colorName: resp.data.product?.variants[0]?.attributes[0]?.values[0]?.name,
                    };
                    this.handleSittingPositions();
                    if (!this.selectedSittingPosition) {
                    }
                    if (!this.selectedSize) {
                        this.handleSizes(resp.data.product.attributes);
                    }
                }
            })
            .catch(e => {
                console.error(e);
            });
    };

    setPartsFromPreconfiguration = (id: string) => {
        this.configuratorSizeLoading = true;
        apolloClient
            .query({ query: BIKE_PRECONFIGURATION, variables: { id } })
            .then(resp => {
                if (resp.data) {
                    const { configuration } = resp.data;
                    // this.selectedParts = configuration.parts;
                    // this.initialParts = configuration.parts;
                    this.handleSelectedParts(configuration?.parts);
                    this.selectedSittingPosition = configuration.sittingPosition;
                    this.selectedSize = configuration.frameSize;
                    if (Array.isArray(configuration.colors)) {
                        const colorType = configuration.colors.find((color: any) => color.frame === 'Color type');
                        // exclude colorType from selectedColors array, because we have separate prop for colorType
                        this.selectedColors = configuration.colors.filter((color: any) => color.frame !== 'Color type');
                        // if preconfig doesn't have colorType, set it to glossy by default
                        this.selectedColorType = colorType?.value || 'glossy';
                    } else {
                        this.selectedBikeVariant = configuration.colors;
                        this.selectedColors = [];
                    }
                    // this.selectedBikeVariant = resp.data.configuration.colors;
                    this.preconfigurationChanged = '';
                    this.getLoadingData();
                }
                this.configuratorSizeLoading = false;
            })
            .catch(e => {
                console.error(e);
                this.configuratorSizeLoading = false;
            });
    };

    handleSelectedParts = (parts: any) => {
        const updatedParts = parts.map((part: any) => {
            const partData = this.configuratorParts
                ?.find(item => item.typeId === part.productTypeId)
                ?.values.find(v => v.referenceProduct.id.includes(part.id.toString()));

            if (partData && partData.referenceProduct) {
                const variant = partData.referenceProduct.variants.find((v: any) => v.id === part.variantId);
                const shortTitle =
                    partData.referenceProduct.translation?.shortTitle || partData.referenceProduct.shortTitle;
                const price = variant?.pricing?.price?.gross?.amount;
                const weight = variant?.weight?.value;
                return { ...part, shortName: shortTitle, price: price, weight: weight };
            } else {
                // return '';
            }

            return part;
        });
        // const existingParts = updatedParts.filter((p: any) => p !== '');
        this.selectedParts = updatedParts;
        this.initialParts = updatedParts;
    };

    // GET MAIN FRAME IMAGE FOR CONFIGURATOR
    get mainImage() {
        if (!this.selectedBike) return '';
        const variant = this.selectedBike?.variants.find((variant: any) => variant.id === this.selectedBikeVariant.id);
        const image = variant?.media?.find((media: any) => media.configuratorOnly);
        return image ? image.url : '';
    }

    // LOAD BIKE PRE CONFIGURATIONS
    getBikePreconfigurations = (isRefresh = true, id: string) => {
        // const category = localStorage.getItem(LocalStorageKey.CATEGORY_ID) || this.bikeCategoryId;
        const templateId = id || this.selectedBike?.id;
        if (!templateId) return;
        apolloClient
            .query({
                query: BIKE_PRECONFIGURATIONS,
                variables: { template: templateId },
            })
            .then(resp => {
                if (resp.data) {
                    this.preconfigurationsList = resp.data.configurations.edges.map((edge: any) => edge.node);
                    if (!isRefresh) {
                        this.configuratorParts = null;
                    }
                    if (!this.preconfigurationId) {
                        const defaultPreconf = this.preconfigurationsList.find((c: any) => c.default);
                        if (defaultPreconf) {
                            this.preconfigurationId = defaultPreconf.id;
                        } else {
                            this.preconfigurationId = resp?.data?.configurations?.edges[0]?.node?.id || '';
                        }
                    }
                }
            })
            .catch(e => {
                console.error(e);
            });
    };

    // SAVE CONFIGURATION
    saveConfiguration = (isCheckout = false) => {
        if (!isCheckout) {
            this.createScreenshot();
        }

        const colorTypeObj = {
            frame: 'Color type',
            value: this.selectedColorType,
        };

        const chosenColors = this.hasSelectedColors ? [...this.selectedColors, colorTypeObj] : this.selectedBikeVariant;
        if (!this.selectedBike) return;
        const variables = {
            title: this.selectedBike.name,
            template: this.selectedBike.id,
            frameSize: this.selectedSize.name || this.selectedSize,
            sittingPosition: this.selectedSittingPosition?.name || this.selectedSittingPosition,
            parts: this.selectedParts,
            colors: chosenColors,
        };
        return apolloClient
            .mutate({
                mutation: CREATE_CONFIGURATION,
                variables,
                fetchPolicy: 'network-only',
            })
            .then(resp => {
                const { sid, id, parts } = resp.data.configurationCreate.configuration;
                this.shareableID = sid;
                this.configurationID = id;
                this.initialParts = parts;
            })
            .catch(e => console.error(e));
    };

    // CREATE A SCREENSHOT USED FOR THE CONFIGURATION
    createScreenshot = () => {
        this.takeScreenshot = true;

        setTimeout(() => {
            const canvas = document.getElementsByTagName('canvas')[0] as any;
            if (!canvas) return;

            const resizedCanvas = document.createElement('canvas');
            const resizedContext = resizedCanvas.getContext('2d');
            // NOTE: Bike image will be slightly smaller then screenshotHeight
            // since canvas has some padding and imgix will trim the padding
            const screenshotHeight = 1500;
            resizedCanvas.width = (screenshotHeight * canvas.width) / canvas.height;
            resizedCanvas.height = screenshotHeight;

            resizedContext?.drawImage(
                canvas,
                0,
                0,
                // Scale width of the image based on screenshotHeight
                screenshotHeight * (canvas.width / canvas.height),
                screenshotHeight,
            );

            const img = resizedCanvas.toDataURL('image/png');
            this.screenshot = img;
            this.takeScreenshot = false;
            this.saveScreenshot();
        }, 2200);
    };

    saveScreenshot = async () => {
        if (!this.screenshot || !this.configurationID) return;

        return apolloClient
            .mutate({
                mutation: SAVE_CONFIGURATION_IMAGE,
                variables: {
                    id: this.configurationID,
                    image: this.screenshot,
                },
            })
            .then(resp => {
                if (resp) {
                    this.screenshot = '';
                }
            });
    };

    // SEND BIKE CONFIGURATION TO EMAILS
    sendConfigurationToEmail = (
        id: string,
        emails: string[],
        channel = process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
    ) => {
        return apolloClient
            .mutate({
                mutation: SEND_CONFIGURATION_TO_EMAIL,
                variables: {
                    id,
                    emails,
                    channel,
                },
            })
            .then(() => {
                this.sendedEmails = emails;
            })
            .catch(e => console.error(e));
    };

    // LOAD BIKE CONFIGURATION PARTS
    getBikeConfigurationParts = (id: string) => {
        const templateId = id || this.selectedBike?.id;
        this.configuratorPageLoading = true;
        apolloClient
            .query({
                query: PARTS,
                variables: { productId: templateId, language: this.locale },
            })
            .then(resp => {
                if (resp.data) {
                    this.configuratorParts = resp.data.product.optionsByType;
                    const categories = resp.data.product.optionsByType.map((item: any) => item.group);
                    this.configuratorCategories = Array.from(new Set(categories)); // create array of unique values of group categories
                    this.mandatoryParts = resp.data.product.requiredTypes;
                    if (resp.data.product.attributes && !this.selectedSize) {
                        this.handleSizes(resp.data.product.attributes);
                    }
                }
                this.configuratorPageLoading = false;
            })
            .catch(e => {
                console.error(e);
                this.configuratorPageLoading = false;
            })
            .finally(() => {
                this.configuratorPageLoading = false;
            });
    };

    // CALCULATE TOTAL PRICE
    get totalPrice() {
        const partsPrice = this.selectedParts.reduce((acc, obj) => {
            return acc + (obj.price || 0);
        }, 0);
        const customColorPrice = this.hasSelectedColors ? 750 : 0;
        return partsPrice + customColorPrice;
    }

    get totalWeight() {
        const baseWeight = this.selectedBikeVariant?.weight || 0;
        const partsWeight = this.selectedParts.reduce((acc, obj) => {
            return acc + (obj.weight || 0);
        }, 0);
        const total = baseWeight + partsWeight;
        return total.toFixed(1);
    }

    get hasSelectedColors() {
        return this.selectedColors.length > 0;
    }

    setSelectedColors = (frame: string, hex: string, id: string, name: string, key: string) => {
        // check if there color is present in array with specific color group
        // if it exists, only update that color group hex (only one color can be selected per color group)
        if (this.selectedColors.map((part: any) => part.frame).includes(frame)) {
            const selectedColorIndex = this.selectedColors.findIndex((part: any) => part.frame === frame);
            this.selectedColors[selectedColorIndex].hex = hex;
            this.selectedColors[selectedColorIndex].id = id;
            this.selectedColors[selectedColorIndex].name = name;
            this.selectedColors[selectedColorIndex].key = key;
        } else {
            this.selectedColors.push({ frame, hex, id, name, key });
        }
        this.selectedColors = [...this.selectedColors];
    };

    handleSizes = (sizesAttributes: any, preconfigSize: string = '') => {
        const sizes = sizesAttributes.filter((s: any) => s?.attribute?.name?.includes('frame-'));
        const filteredSizes = sizes.filter((s: any) => s.values.length > 0);
        const normalizedSizes = filteredSizes.map((size: any) => ({
            id: size.attribute.id,
            name: size.attribute.name.split('-')[1],
            from: parseInt(size.values[0].name.split('-')[0]),
            to: parseInt(size.values[0].name.split('-')[1]),
        }));

        const sorted = sortByAttribute(normalizedSizes, 'from');
        this.sizes = sorted;

        if (preconfigSize) {
            const selectedSize = sorted.find((item: any) => item.name === preconfigSize);
            return (this.selectedSize = selectedSize);
        }

        if (sorted.length > 0 && !this.selectedSize) {
            const sizeM = sorted.find((item: any) => item.name.includes('m'));
            if (sizeM) {
                this.selectedSize = { name: sizeM.name, id: sizeM.id };
            } else {
                this.selectedSize = { name: sorted[0].name, id: sorted[0].id };
            }
            this.getBikeConfigurationsData();
        }
    };

    handleChangeSize(size: any) {
        if (size) {
            this.selectedSize = { name: size.name, id: size.id };
            this.configuratorSizeLoading = true;
            this.getBikeConfigurationsData();
        }
    }

    handleSittingPositions = () => {
        const positions = this.selectedBike?.sittingPositions?.map((position: SittingPosition) => {
            position.name = capitalizeFirstLetter(position.name);
            return position;
        });
        this.sittingPositions = positions;
        if (positions?.length > 0 && !this.selectedSittingPosition) {
            // in case of single sitting position, pre-select it
            this.selectedSittingPosition = positions[0];
        }
        if (positions?.length === 0) {
            this.sittingPositions = [];
        }
    };

    // LOAD BIKE CONFIGURATIONS DATA
    getBikeConfigurationsData = (id?: string) => {
        const productId = id || this.selectedBike?.id;

        if (!productId) return;

        apolloClient
            .query({
                query: PRODUCT_PART_MODEL,
                variables: { id: productId, size: this.selectedSize.id },
                fetchPolicy: 'no-cache',
            })
            .then(resp => {
                if (resp.data) {
                    // this.isFirstLoad = true;
                    this.getModelData = resp.data;
                    this.getLoadingData(!!id);
                }
                this.configuratorSizeLoading = false;
            })
            .catch(e => {
                console.error(e);
                this.configuratorSizeLoading = false;
            });
    };

    openOverlayCanvas = () => {
        this.isOpenOverlayCanvas = true;
    };

    closeOverlayCanvas = () => {
        this.isOpenOverlayCanvas = false;
    };

    resetStore = () => {
        this.listenForNavigationChange = true;
        this.selectedParts = [];
        this.selectedSize = '';
        this.selectedBike = null;
        this.selectedBikeVariant = null;
        this.bikeCategoryId = '';
        this.selectedSittingPosition = null;
        this.preconfigurationId = '';
        this.category.name = null;
        this.bike = '';
        this.shareableID = '';
        this.getModelData = null;
        this.loadingData = [];
        this.isFirstLoad = true;
        this.configurationID = '';
        this.initialParts = [];
        this.mandatoryParts = [];
        this.configuratorParts = [];
        this.selectedProductTypeId = null;
        this.selectedGroupType = '';
        this.isOpenOverlayCanvas = false;
        this.configuratorPageLoading = true;
        this.bikeModelPageLoading = true;
        this.selectedColors = [];
        this.loadingModelIds = [];
        this.menuPopupIgnoreClickOutside = false;
    };

    getLoadingData = (overrideFirstLoad = false) => {
        if (!this.getModelData || !this.getModelData.productPartModels) return;

        if (!this.configuratorParts || this.configuratorParts.length === 0) {
            return;
        }
        this.loadingData = [...this.getModelData.productPartModels];

        const productPartModels: any[] = this.getModelData.productPartModels.map(item => ({ ...item, visible: true }));

        if (overrideFirstLoad) {
            return this.getShow3DModel(productPartModels);
        }

        if (this.isFirstLoad) {
            const initSelectedPart: any[] = [];

            for (const item of productPartModels) {
                const isHasItem = this.configuratorParts.find(
                    (obt: OptionsByType) =>
                        obt.values.map(v => v.referenceProduct.id).includes(item.id) &&
                        !initSelectedPart.find(i => i.productTypeId === obt.typeId),
                );

                if (isHasItem) {
                    const part = isHasItem.values.find(v => v.referenceProduct.id.includes(item.id));
                    const isPartMandatory = this.mandatoryParts.find((p: any) => p.id === isHasItem.typeId);

                    if (part && isPartMandatory) {
                        initSelectedPart.push({
                            id: part.referenceProduct?.id,
                            partName: isHasItem.type,
                            price: part.referenceProduct?.variants[0]?.pricing?.price?.gross?.amount,
                            productTypeId: item.productType.id,
                            weight: part.referenceProduct?.variants[0]?.weight?.value || 0,
                            variantName: part.name,
                            variantId: part.referenceProduct?.variants[0]?.id,
                            group: isHasItem.group,
                        });

                        const isHasLoaded3d = this.loadingModelIds.find(m => m.id === part.referenceProduct?.id);
                        if (!isHasLoaded3d) {
                            this.loadingModelIds.push({
                                id: part.referenceProduct?.id,
                                isLoaded: true,
                                isLoading: false,
                            });
                        }
                    }
                }
            }

            if (this.initialParts.length === 0) {
                this.initialParts = initSelectedPart;
            }
            this.isFirstLoad = false;
            // if preconfiguration exists load first one, if not load mandatory parts
            if (this.preconfigurationId && this.selectedBike) {
                this.setPartsFromPreconfiguration(this.preconfigurationId);
            } else {
                this.selectedParts = initSelectedPart;
                this.getShow3DModel(productPartModels);
                this.initialParts = initSelectedPart;
            }
        } else {
            this.getShow3DModel(productPartModels);
        }
    };

    getShow3DModel = (data: any[]) => {
        this.loadingData = [];
        for (const d of data) {
            const group = this.selectedParts.find(i => i.productTypeId === d.productType?.id)?.group;
            const isHasItem = this.selectedParts.find(i => i.productTypeId === d.productType?.id && i.id === d.id);
            if (isHasItem || !d.id || !d.productType || d.productType?.isBikeType) {
                this.loadingData.push({
                    ...d,
                    visible: true,
                    group: group,
                });
            } else {
                this.loadingData.push({
                    ...d,
                    visible: false,
                    group: group,
                });
            }
        }
    };

    getProductBySid = (sid: string) => {
        return apolloClient
            .query({
                query: GET_BIKE_BY_SID,
                variables: { sid, language: this.locale },
            })
            .then(resp => {
                if (resp.data) {
                    const { configuration } = resp.data;
                    if (!configuration) return;

                    // set selected data from configuration
                    this.selectedParts = configuration?.parts;
                    this.initialParts = configuration?.parts;
                    this.selectedSittingPosition = configuration.sittingPosition;
                    this.selectedSize = configuration.frameSize;
                    if (Array.isArray(configuration.colors)) {
                        const colorType = configuration.colors.find((color: any) => color.frame === 'Color type');
                        // exclude colorType from selectedColors array, because we have separate prop for colorType
                        this.selectedColors = configuration.colors.filter((color: any) => color.frame !== 'Color type');
                        // if preconfig doesn't have colorType, set it to glossy by default
                        this.selectedColorType = colorType?.value || 'glossy';
                    } else {
                        this.selectedBikeVariant = configuration.colors;
                        this.selectedColors = [];
                    }
                    this.preconfigurationChanged = '';

                    // set parts
                    this.configuratorParts = configuration.template.optionsByType;
                    const categories = configuration.template.optionsByType.map((item: any) => item.group);
                    this.configuratorCategories = Array.from(new Set(categories)); // create array of unique values of group categories
                    this.mandatoryParts = configuration.template.requiredTypes;

                    // set sidebar data
                    this.selectedBike = configuration.template;
                    this.handleSittingPositions();
                    this.handleSizes(configuration.template.attributes, configuration.frameSize);
                    this.getBikePreconfigurations(true, configuration.template.id);

                    // load 3d
                    this.getBikeConfigurationsData(configuration.template.id);
                    this.isFirstLoad = false;

                    if (configuration.referralEmail) {
                        // if referral item exist in LS delete it and override it on line 781
                        if (localStorage.getItem(LocalStorageKey.REFERRAL_ITEM)) {
                            localStorage.removeItem(LocalStorageKey.REFERRAL_ITEM);
                        }

                        const oneMonth = daysToMilliseconds(30);
                        setLSKeyWithExpiry(LocalStorageKey.REFERRAL_ITEM, configuration.id, oneMonth);
                    }
                    return configuration;
                }
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                this.configuratorPageLoading = false;
            });
    };
}

export default createContext(new ConfiguratorStore());
