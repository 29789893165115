import colorPickerImg from 'assets/images/color-picker.png';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { MenuPopup } from 'shared/components/ui/MenuPopup/MenuPopup';
import { Portal } from 'shared/components/ui/Portal/Portal';
import useClickOutside from 'shared/providers/useClickOutside';

import { ColorPicker } from '../ColorPicker/ColorPicker';

import styles from './ColorPickerPopup.module.scss';

type ColorPickerProps = {
    active?: boolean;
    clicked: (name: string) => void;
    hasCustomColor?: boolean;
    onClose: () => void;
    smaller?: boolean;
};

export const ColorPickerPopup: FC<ColorPickerProps> = ({ active, hasCustomColor, clicked, smaller, onClose }) => {
    const [coords, setCoords] = useState({ top: '0', left: '0' });
    const colorPickerRef = useRef<HTMLDivElement>(null);
    const portalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (active) {
            const current = portalRef.current;
            window.addEventListener('resize', () => openPortal(current));

            return () => window.removeEventListener('resize', () => openPortal(current));
        }
    }, [active]);

    useClickOutside(colorPickerRef, () => onClose());

    const openPortal = (e: any) => {
        const rect = e.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2, // add half the width of the button for centering
            top: rect.y + window.scrollY, // add scrollY offset, as soon as getBoundingClientRect takes on screen coords
        });
    };

    return (
        <div className={styles.color_picker_wrapper}>
            <div
                className={clsx(styles.color_picker, smaller && styles.smaller, hasCustomColor && styles.active)}
                onClick={
                    active
                        ? () => onClose()
                        : (e: any) => {
                              clicked('color picker');
                              openPortal(e.target);
                          }
                }
                ref={portalRef}
            >
                <img src={colorPickerImg} alt="color picker" />
            </div>

            {active && (
                <Portal>
                    <div style={{ position: 'absolute', top: coords.top, left: coords.left }} ref={colorPickerRef}>
                        <MenuPopup width={360} className={styles.menu_popup} arrowClassName={styles.menu_popup_arrow}>
                            <ColorPicker />
                        </MenuPopup>
                    </div>
                </Portal>
            )}
        </div>
    );
};
