import { configure } from 'mobx';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CookieConsent } from 'shared/components/layout/CookieConsent/CookieConsent';
import { LocalStorageKey } from 'shared/helpers/localStorage';

import { Routes } from './Routes';
import { Layout } from './shared/components/layout';
import './i18n';
import { GoogleTagManagerInitializer } from './shared/components/layout/GtmInit/GtmInit';
import { ContinueConfigModal } from './shared/components/ui/ContinueConfigModal/ContinueConfigModal';
import { DeviceDetectorProvider } from './shared/providers/DeviceDetector';

import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

configure({
    enforceActions: 'never',
});

declare global {
    interface Window {
        dataLayer: any;
        oz: any;
        gtag: any;
    }
}

const App = () => {
    //TODO: Change this when language selector is implemented
    useEffect(() => {
        if (process.env.REACT_APP_LOCALE) {
            const lang = localStorage.getItem(LocalStorageKey.LANGUAGE);
            if (!lang) localStorage.setItem(LocalStorageKey.LANGUAGE, process.env.REACT_APP_LOCALE.toUpperCase());
        }
        window.dataLayer = window.dataLayer || [];
    }, []);

    configure({
        enforceActions: 'never',
    });

    return (
        <>
            <BrowserRouter>
                <GoogleTagManagerInitializer />
                <DeviceDetectorProvider device="desktop">
                    <Layout>
                        <Routes />
                    </Layout>
                </DeviceDetectorProvider>
            </BrowserRouter>

            <div id="popup-portal"></div>

            <ToastContainer position="bottom-right" />
            <CookieConsent />
            <ContinueConfigModal />
        </>
    );
};

export default App;
