const scrollDepths = [
    { value: 25, hasTriggered: false },
    { value: 50, hasTriggered: false },
    { value: 75, hasTriggered: false },
    { value: 90, hasTriggered: false },
];

export const sendGtmEvent = ({ action, category, label, value }: any) => {
    if (!window.gtag) return;
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
    });
};

const handleScroll = () => {
    const { scrollHeight } = document.body;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollPercent = ((scrollTop + windowHeight) / scrollHeight) * 100;
    const roundedPercent = Math.round(scrollPercent);

    scrollDepths.forEach(depth => {
        if (roundedPercent >= depth.value && !depth.hasTriggered) {
            sendGtmEvent({
                action: 'scroll_depth',
                category: 'Engagement',
                label: `scroll_${depth.value}`,
                value: depth.value,
            });
            // eslint-disable-next-line no-param-reassign
            depth.hasTriggered = true;

            if (depth.value === 90) {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    });
};

export const trackScrollDepth = () => {
    window.addEventListener('scroll', handleScroll);
};

export const resetTrackScrollDepth = () => {
    scrollDepths.forEach(depth => {
        // eslint-disable-next-line no-param-reassign
        depth.hasTriggered = false;
    });
    // Reattach the event listener after resetting the scroll depths on a new page
    window.addEventListener('scroll', handleScroll);
};
