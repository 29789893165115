import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';

import { InstallmentOption } from '../InstallmentOption/InstallmentOption';

import styles from './InstallmentPayment.module.scss';

const INSTALLMENTS = [6, 12, 24];
const MOST_POPULAR = 12;

export const InstallmentPayment = observer(() => {
    const [selectedInstallment, setSelectedInstallment] = useState<null | number>(null);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const payByInstallments = async () => {
        setIsLoading(true);
        const installmentsGateway = checkoutStore.data?.availablePaymentGateways?.find(
            gateway => gateway.id === 'mirumee.payments.dummy',
        );
        if (!installmentsGateway || !checkoutStore.data?.id) return;

        const financeInput = {
            grossAmount: checkoutStore.totalPrice,
            paymentDuration: selectedInstallment,
        };

        try {
            await checkoutStore.createPayment(installmentsGateway.id);
            // await checkoutStore.checkoutComplete(checkoutStore.data?.id);
            await checkoutStore.financeCreate(checkoutStore.data?.id, financeInput);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.instalmentPayment}>
            <p>{t('installmentsTitle')}</p>
            <div className={styles.options}>
                {INSTALLMENTS.map(item => (
                    <InstallmentOption
                        key={item}
                        price={checkoutStore.totalPrice}
                        installments={item}
                        onClick={() => setSelectedInstallment(item)}
                        selected={selectedInstallment}
                        popular={MOST_POPULAR}
                    />
                ))}
            </div>
            <p dangerouslySetInnerHTML={{ __html: t('installmentsInfo') }} />
            <Button
                className={styles.button}
                isLoading={isLoading}
                isDisabled={!selectedInstallment}
                onClick={() => payByInstallments()}
            >
                {t('installmentsButton')}
            </Button>
        </div>
    );
});
