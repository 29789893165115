import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../../shared/components/ui';
import { DeviceDetectorContext } from '../../../../shared/providers/DeviceDetector';
import { groupSelectedParts, localizePrice } from '../../../../shared/providers/utils';
import ConfiguratorStore from '../../../../stores/Configurator.store';

import styles from './SummaryDrawer.module.scss';

type SummaryDrawerProps = {
    open: boolean;
    onClose: () => void;
    height?: number;
};

export const SummaryDrawer: FC<SummaryDrawerProps> = observer(({ open, onClose, height }) => {
    const configuratorStore = useContext(ConfiguratorStore);
    const { t } = useTranslation();
    const ausstattung = configuratorStore.selectedParts.find((part: any) => part.partName === 'Ausstattung');

    const [summaryHeight, setSummaryHeight] = useState(450);

    const { device } = useContext(DeviceDetectorContext);

    useEffect(() => {
        if (configuratorStore.sidebarExpanded) {
            if (height && height > 0) {
                setSummaryHeight(height);
            }
        } else {
            setTimeout(() => {
                setSummaryHeight(0);
            }, 400);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuratorStore.sidebarExpanded]);

    return (
        <div
            className={clsx(styles.summary_drawer, open && styles.opened)}
            style={{
                height: height && open ? `${height}px` : `${device !== 'desktop' ? '45%' : `${summaryHeight}px`}`,
            }}
        >
            <div className={clsx(styles.hide_summary)} onClick={() => onClose()}>
                <h6>{t('hideSummary')}</h6>
                <Icon name="arrow_down" className={styles.icon} />
            </div>
            <div className={styles.scroll_wrapper}>
                <div className={styles.title_wrapper}>
                    <h5>{t('checkoutHeroTitle')}</h5>
                </div>

                <div className={styles.selected_parts}>
                    <div className={styles.part_section}>
                        <h6 className={styles.variant}>{configuratorStore.selectedBike?.name}</h6>
                        <div className={styles.highlighted}>
                            <h6>{ausstattung?.shortName}</h6>
                            <h6>CHF {localizePrice(ausstattung?.price)}</h6>
                        </div>
                    </div>
                    {groupSelectedParts(configuratorStore.selectedParts).map((part: any) => {
                        return (
                            <div key={part.group} className={styles.part_section}>
                                <h6 className={styles.group}>{t(part.group)}</h6>
                                {part?.parts?.map((item: any) => {
                                    if (item.partName === 'Ausstattung') return '';
                                    return (
                                        <div key={item.id} className={styles.part}>
                                            <h6 className={styles.variant}>{item.shortName || item.variantName}</h6>
                                            <h6 className={styles.price}>CHF {localizePrice(item.price)}</h6>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
