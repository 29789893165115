import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { Input, Button, Label } from 'shared/components/ui';
import { EMAIL_REGEX, PHONE_REGEX } from 'shared/helpers/validators';

import { LocalStorageKey } from '../../../../../../shared/helpers/localStorage';

import styles from './GuestCard.module.scss';

type GuestCardProps = {
    data: any;
    onNextStep: (email: string) => void;
    onOffer?: (email: string) => void;
    isLoading?: boolean;
    buttonText?: string;
    className?: string;
    phoneRequired?: boolean;
};

export const GuestCard: React.FC<GuestCardProps> = ({
    data,
    onNextStep,
    isLoading,
    buttonText,
    className,
    phoneRequired = false,
}) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (data.email) setValue('email', data.email);
        if (data.phone) setValue('phone', data.phone);
    }, [data.email, data.phone]); // eslint-disable-line

    const nextStep = (value: any) => {
        onNextStep(value);
        if (value.email) {
            localStorage.setItem(LocalStorageKey.EMAIL, value.email);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(nextStep)} className={className}>
                <div className={styles.inputs}>
                    <div>
                        <Input
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('checkoutEmailError'),
                                },
                            })}
                            className={styles.input}
                            label={t('checkoutEmailLabel')}
                            placeholder={t('checkoutEmailPlaceholder')}
                            labelWrapperClassName={styles.labelWrapper}
                            autoFocus
                            error={errors?.email as any}
                        />
                        {errors?.email && <div className={styles.error}>{t('checkoutEmailError')}</div>}
                    </div>
                    <div>
                        <Label
                            title={t('phoneNumberTitle')}
                            tooltipText={t('phoneNumberTooltip')}
                            className={styles.labelWrapper}
                        />
                        <Controller
                            name="phone"
                            control={control}
                            rules={{
                                required: phoneRequired,
                                pattern: {
                                    value: PHONE_REGEX,
                                    message: t('phoneRequiresDigits'),
                                },
                            }}
                            render={({ field: { value, onChange } }) => (
                                <PhoneInput
                                    placeholder={t('phonePlaceholder')}
                                    value={value}
                                    onChange={onChange}
                                    country={'ch'}
                                    preferredCountries={['ch', 'de', 'at']}
                                />
                            )}
                        />
                        {errors?.phone && <div className={styles.error}>{t('phoneRequiresDigits')}</div>}
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button htmlType="submit" className={styles.next} isLoading={isLoading}>
                        {buttonText || t('checkoutLoginNext')}
                    </Button>
                </div>
            </form>
        </>
    );
};
