import clsx from 'clsx';
import { Sidebar as BikeModelSidebar } from 'pages/BikeModel/_components/Sidebar/Sidebar';
import { ConfiguratorSidebar } from 'pages/Configurator/_components/ConfiguratorSidebar/ConfiguratorSidebar';
import { MobileTabletSidebar } from 'pages/Configurator/_components/MobileTabletSidebar/MobileTabletSidebar';
import React, { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { CheckoutSidebar } from '../../../../pages/Checkout/_components/CheckoutSidebar/CheckoutSidebar';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { DeviceDetectorContext } from '../../../providers/DeviceDetector';

import styles from './Sidebar.module.scss';

type SidebarProps = {
    type: 'bikeModel' | 'configurator' | 'checkout';
    bikeModelData?: any;
    setUsps?: (usp: string[]) => void;
    setDescription?: (desc: string) => void;
    loading?: boolean;
};

export const Sidebar: FC<SidebarProps> = ({ type, bikeModelData, loading = false }) => {
    const { device } = useContext(DeviceDetectorContext);
    const location = useLocation();
    const configuratorStore = useContext(ConfiguratorStore);

    const [expandSidebar, setExpandSidebar] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(362);
    const [disableTransition, setDisableTransition] = useState(false);
    const [drawerHeight, setDrawerHeight] = useState(0);

    const sidebarMainRef = useRef<HTMLDivElement>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const calledOnce = useRef(false);

    // 60% of screen is max width for sidebar
    const sidebarMaxWidth = 0.6 * window.innerWidth;

    const handleChangeSize = (mouseDownEvent: any) => {
        setDisableTransition(true);

        const onMouseMove = (mouseMoveEvent: any) => {
            const width =
                // eslint-disable-next-line no-nested-ternary
                sidebarWidth + mouseDownEvent.pageX - mouseMoveEvent.pageX <= 362
                    ? 362
                    : sidebarWidth + mouseDownEvent.pageX - mouseMoveEvent.pageX >= sidebarMaxWidth
                    ? sidebarMaxWidth
                    : sidebarWidth + mouseDownEvent.pageX - mouseMoveEvent.pageX;
            setSidebarWidth(width);
            configuratorStore.sidebarWidth = width;
        };
        const onMouseUp = () => {
            document.body.removeEventListener('mousemove', onMouseMove);
            document.body.removeEventListener('mouseup', onMouseUp);
            setDisableTransition(false);
        };

        document.body.addEventListener('mousemove', onMouseMove);
        document.body.addEventListener('mouseup', onMouseUp);
    };

    useEffect(() => {
        if (expandSidebar) {
            // set the initial width of sidebar if it's changed already user
            setSidebarWidth(configuratorStore.sidebarWidth);
            configuratorStore.sidebarExpanded = true;
        } else {
            setSidebarWidth(362);
            configuratorStore.sidebarExpanded = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandSidebar]);

    useLayoutEffect(() => {
        if (configuratorStore.loadingEnded && device === 'desktop') {
            if (!sidebarMainRef.current) return;

            // ResizeObserver has to be used because we are tracking div height with ref
            // when sidebarMainRef changes height (flex: row) execute recalculate the drawer height
            const resizeObserver = new ResizeObserver(() => {
                calculateDrawerHeight();
            });
            resizeObserver.observe(sidebarMainRef.current);

            // clean up
            return () => resizeObserver.disconnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuratorStore.loadingEnded, sidebarMainRef.current?.clientHeight]);

    // use this to set default width of sidebar
    // this will be executed only once (calledOnce.current)
    useEffect(() => {
        if (configuratorStore.loadingEnded && device === 'desktop') {
            if (calledOnce.current) return;

            const mainHeight = sidebarRef.current?.clientHeight;
            if (!mainHeight) return;

            const percentage = (drawerHeight / mainHeight) * 100;
            const fixed = Math.round(percentage);

            // check if drawer height is less then 45% of sidebar
            // if it is, increase the sidebar width by 60% of the screen
            if (fixed <= 45) {
                // const initialWidth = 0.6 * window.innerWidth;

                configuratorStore.sidebarWidth = sidebarMaxWidth;
            }
            // use this useEffect only once
            calledOnce.current = true;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerHeight]);

    // calculate drawer height manually because it's absolutely positioned and invisible by default
    // meaning we cannot give it height of auto
    const calculateDrawerHeight = () => {
        const sidebarHeight = sidebarRef.current?.clientHeight;
        const mainHeight = sidebarMainRef.current?.clientHeight;

        if (!sidebarHeight || !mainHeight) return;

        // 24px is sidebar title
        const fullMainHeight = mainHeight + 24;
        const padding = 40;

        const newHeight = sidebarHeight - fullMainHeight - padding;

        setDrawerHeight(newHeight);
    };

    return (
        <div
            className={clsx(
                styles.side_bar,
                device !== 'desktop' && type === 'configurator' && styles.mobile_tablet_wrapper,
                location.pathname.includes('checkout') && styles.checkout,
                device === 'desktop' && !expandSidebar && styles.expand_sidebar,
            )}
            style={{
                maxWidth: device === 'desktop' ? `${sidebarWidth}px` : '100%',
                transition: `${disableTransition ? 'unset' : 'max-width 150ms ease-in'}`,
                minWidth: device === 'desktop' ? '362px' : 'auto',
            }}
        >
            {type === 'bikeModel' && <BikeModelSidebar bikeModelData={bikeModelData} loading={loading} />}
            {type === 'configurator' && (
                <>
                    {device !== 'desktop' ? (
                        <MobileTabletSidebar />
                    ) : (
                        <>
                            {expandSidebar && <div className={styles.resizable} onMouseDown={handleChangeSize} />}
                            <ConfiguratorSidebar
                                onExpand={isOpen => setExpandSidebar(isOpen)}
                                drawerHeight={drawerHeight}
                                sidebarRef={sidebarRef}
                                sidebarMainRef={sidebarMainRef}
                            />
                        </>
                    )}
                </>
            )}
            {type === 'checkout' && <CheckoutSidebar />}
        </div>
    );
};
