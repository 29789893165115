import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'shared/components/ui';
import { CookieHelper } from 'shared/helpers/cookie';

import styles from './CookieConsent.module.scss';

declare global {
    interface Window {
        gtag: any;
    }
}
export const CookieConsent = () => {
    const { t } = useTranslation();
    const [showCookieConsent, setShowCookieConsent] = useState(false);
    const [preferences, setPreferences] = useState(true);
    const [statistics, setStatistics] = useState(true);
    const [marketing, setMarketing] = useState(true);

    useEffect(() => {
        const acceptedCookieConsent = !!CookieHelper.get('cookie_consent');

        setShowCookieConsent(!acceptedCookieConsent);
        if (!acceptedCookieConsent) {
            setDefaultCookieConsent();
        }
    }, []);

    const setDefaultCookieConsent = () => {
        CookieHelper.set('functionality_storage', 'granted');
        CookieHelper.set('ad_storage', 'denied');
        CookieHelper.set('analytics_storage', 'denied');
        CookieHelper.set('personalization_storage', 'denied');
    };

    const updateCookieConsentState = () => {
        CookieHelper.set('cookie_consent', 'accepted');
        CookieHelper.set('functionality_storage', 'granted');
        CookieHelper.set('ad_storage', marketing ? 'granted' : 'denied');
        CookieHelper.set('analytics_storage', statistics ? 'granted' : 'denied');
        CookieHelper.set('personalization_storage', preferences ? 'granted' : 'denied');

        if (window.gtag === undefined) return;

        window.gtag('consent', 'update', {
            ad_storage: marketing ? 'granted' : 'denied',
            analytics_storage: statistics ? 'granted' : 'denied',
            personalization_storage: preferences ? 'granted' : 'denied',
        });

        setShowCookieConsent(false);
    };

    return showCookieConsent ? (
        <section className={styles.root}>
            <div className={styles.container}>
                <div className={styles.descriptionWrapper}>
                    <p className={styles.description}>{t('cookieConsent.description')}</p>
                </div>
                <div className={styles.checkboxWrapper}>
                    <Checkbox label={t('cookieConsent.necessary')} disabled={true} checked={true} />
                    <Checkbox
                        label={t('cookieConsent.preferences')}
                        checked={preferences}
                        onChange={e => setPreferences(e.currentTarget.checked)}
                    />
                    <Checkbox
                        label={t('cookieConsent.statistics')}
                        checked={statistics}
                        onChange={e => setStatistics(e.currentTarget.checked)}
                    />
                    <Checkbox
                        label={t('cookieConsent.marketing')}
                        checked={marketing}
                        onChange={e => setMarketing(e.currentTarget.checked)}
                    />
                </div>
                <div className={styles.policyWrapper}>
                    <a
                        className={styles.policy}
                        href={t('cookieConsent.privacyPolicyLink')}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('cookieConsent.policy')}
                    </a>
                </div>
                <div className={styles.buttonWrapper}>
                    <Button className={styles.button} postIcon="arrow_right" onClick={updateCookieConsentState}>
                        {t('cookieConsent.accept')}
                    </Button>
                </div>
            </div>
        </section>
    ) : (
        <></>
    );
};
