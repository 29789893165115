import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckoutStore from '../../../../../../stores/Checkout.store';
import { InstallmentPayment } from '../../../Payment/_components/InstallmentPayment/InstallmentPayment';
import { StripePayment } from '../../../Payment/_components/StripePayment/StripePayment';
import { TabItem } from '../../../Payment/Payment';

import styles from './ExpertPayment.module.scss';

type ExpertPaymentProps = {};

export const ExpertPayment: FC<ExpertPaymentProps> = () => {
    const checkoutStore = useContext(CheckoutStore);

    const [tab, setTab] = useState(1);

    const { t } = useTranslation();

    return (
        <div className={styles.payments}>
            <TabItem selected={tab === 2} onClick={() => setTab(2)}>
                {checkoutStore.prepaymentPercentage >= 100
                    ? t('paymentStripeInFull')
                    : t('paymentStripe').replace('{percentage}', `${checkoutStore.prepaymentPercentage}%`)}
            </TabItem>
            {tab === 2 && <StripePayment />}

            <TabItem selected={tab === 1} onClick={() => setTab(1)}>
                {t('paymentFinance')}
            </TabItem>
            {tab === 1 && <InstallmentPayment />}
        </div>
    );
};
