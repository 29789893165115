import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { localizePrice } from '../../../../../../shared/providers/utils';
import CheckoutStore from '../../../../../../stores/Checkout.store';

import styles from './BicycleSection.module.scss';

type BicycleSectionProps = {};

export const BicycleSection: FC<BicycleSectionProps> = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const [bicycleData, setBicycleData] = useState<any>([]);
    const [equipment, setEquipment] = useState<any>();
    const { t } = useTranslation();

    useEffect(() => {
        if (checkoutStore.data?.linesByVariantGroup?.length) {
            const bicycleLines = checkoutStore.data?.linesByVariantGroup.find((d: any) => d.group === 'BICYCLE')?.lines;
            const equipment = checkoutStore.data?.linesByVariantGroup
                .find((d: any) => d.group === 'DRIVE')
                ?.lines.find((l: any) => l.variant.product.productType.name === 'Ausstattung');

            setBicycleData(bicycleLines);
            setEquipment(equipment);
        }
    }, [checkoutStore.data?.linesByVariantGroup]);

    return (
        <div className={styles.bicycle_section}>
            <h6 className={styles.title}>{t('BICYCLE')}</h6>
            {bicycleData[0] && (
                <div className={styles.line}>
                    <h6 className={styles.name}>{bicycleData[0].variant.product.name}</h6>
                    {equipment && (
                        <h6 className={styles.price}>
                            {equipment.totalPrice.gross.currency} {localizePrice(equipment.totalPrice.gross.amount)}
                        </h6>
                    )}
                </div>
            )}
            {equipment && (
                <div className={styles.line}>
                    <h6 className={styles.name}>{equipment.variant.product.name}</h6>
                </div>
            )}
        </div>
    );
});
