import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useUpdateCheckoutMetadata } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import React, { FC, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { Button, Icon, Tags } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { localizePrice } from 'shared/providers/utils';
import { SittingPosition } from 'shared/types/configurator';
import ConfiguratorStore from 'stores/Configurator.store';

import { InstalmentsModal } from '../../../../shared/components/ui/InstalmentsModal/InstalmentsModal';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import { ColorSection } from '../ColorSection/ColorSection';
import { PreConfigurations } from '../Preconfigurations/Preconfigurations';
import { SummaryDrawer } from '../SummaryDrawer/SummaryDrawer';

import styles from './ConfiguratorSidebar.module.scss';

type ConfiguratorSidebarProps = {
    onExpand: (isOpen: boolean) => void;
    drawerHeight: number;
    sidebarMainRef: any;
    sidebarRef: any;
};

export const ConfiguratorSidebar: FC<ConfiguratorSidebarProps> = observer(
    ({ onExpand, drawerHeight, sidebarMainRef, sidebarRef }) => {
        const configuratorStore = useContext(ConfiguratorStore);
        const checkoutStore = useContext(CheckoutStore);
        const [loading, setLoading] = useState(false);
        const [openSummary, setOpenSummary] = useState(false);
        const [instalmentsModal, setInstalmentsModal] = useState(false);
        const [overflowHidden, setOverflowHidden] = useState(false);

        const { t } = useTranslation();
        const navigator = useNavigate();
        const checkoutID = localStorage.getItem(LocalStorageKey.CHECKOUT_ID);
        const configPrice = localizePrice(configuratorStore.totalPrice / 24);

        const [updateCheckoutMetadata] = useUpdateCheckoutMetadata();

        useEffect(() => {
            if (checkoutID) checkoutStore.getCheckout(checkoutID);
        }, [checkoutID]); // eslint-disable-line

        // After the checkout data is loaded compare the bike used in the checkout
        // with the loaded bike. If the same bike is used, leave the checkoutID and use it for the session
        // but if it's a different bike loaded than the one in the saved checkout, remove the checkoutID from localStorage
        // so a new checkout object is created
        useEffect(() => {
            if (checkoutStore.data && configuratorStore.selectedBike) {
                const checkoutTemplate = checkoutStore.data.metadata.find(
                    (item: any) => item.key === 'templateId',
                )?.value;
                if (checkoutTemplate && checkoutTemplate !== configuratorStore.selectedBike.id) {
                    localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);
                }
            }
        }, [checkoutStore.data, configuratorStore.selectedBike]);

        const createScreenshot = async () => {
            setLoading(true);
            checkoutStore.createScreenshot();
        };

        useEffect(() => {
            async function goToCheckout() {
                try {
                    setLoading(true);
                    configuratorStore.listenForNavigationChange = false;

                    if (checkoutID) {
                        await checkoutStore.updateCheckoutLines(
                            configuratorStore.selectedParts,
                            configuratorStore.selectedColors,
                            configuratorStore.selectedBikeVariant,
                            checkoutID,
                        );
                    } else {
                        await checkoutStore.createCheckout(
                            configuratorStore.selectedParts,
                            configuratorStore.selectedColors,
                            configuratorStore.selectedBikeVariant,
                        );
                    }

                    const id = checkoutStore.data?.id;
                    if (!id) {
                        return console.error('No checkout ID.');
                    }

                    await checkoutStore.saveScreenshot(id);
                    await configuratorStore.saveConfiguration(true);
                    await updateCheckoutMetadata();
                    configuratorStore.configurationExists = true;

                    window.dataLayer.push({
                        event: gtmEvents.CHECKOUT_CREATE,
                    });

                    await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_CREATE);

                    // Replace the configurator URL with the one including SID
                    // So if user uses browsers back arrow it still will load the latest configuration he created
                    const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
                    await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_CREATE, SID);

                    navigator(`${AppRoutes.configurator}/${SID}`, { replace: true });
                    // navigator(AppRoutes.checkout.replace(':id', id));
                    navigator(AppRoutes.serviceUpgrade);
                    localStorage.setItem(LocalStorageKey.CHECKOUT_ID, id);
                } catch (error) {
                    console.error('error', error);
                } finally {
                    setLoading(false);
                }
            }

            if (checkoutStore.screenshot) {
                goToCheckout();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checkoutStore.screenshot]);

        useLayoutEffect(() => {
            if (configuratorStore.sidebarExpanded) {
                setOverflowHidden(true);
            } else {
                setTimeout(() => {
                    setOverflowHidden(false);
                }, 400);
            }
        }, [configuratorStore.sidebarExpanded]);

        return (
            <>
                <div
                    className={clsx(
                        styles.configurator,
                        overflowHidden && styles.overflow_hidden,
                        configuratorStore.sidebarWidth > 400 && configuratorStore.sidebarExpanded && styles.smaller,
                    )}
                    id="conf"
                    ref={sidebarRef}
                >
                    <h5>{t('sidebarSpecificationTitle')}</h5>

                    <div
                        className={clsx(styles.sidebar_main, configuratorStore.sidebarWidth > 400 && styles.wrap)}
                        id="sidebarMain"
                        ref={sidebarMainRef}
                    >
                        <ColorSection />

                        {configuratorStore.sizes.length > 0 ? (
                            <Tags
                                className={styles.bikeSizes}
                                type="frame size"
                                data={configuratorStore.sizes}
                                title={t('sidebarFrameSizeTitle')}
                                active={configuratorStore.selectedSize.name || configuratorStore.selectedSize}
                                onSelect={async (size: any) => {
                                    configuratorStore.handleChangeSize(size);
                                    window.dataLayer.push({
                                        event: gtmEvents.BIKE_SIZE,
                                        size: size.name,
                                        category: configuratorStore.bike,
                                        bikeModel: configuratorStore.selectedBike.name,
                                    });
                                    await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_SIZE, size.name);
                                }}
                            />
                        ) : (
                            !configuratorStore.loadingEnded && (
                                <Skeleton
                                    count={5}
                                    circle={true}
                                    width={40}
                                    height={40}
                                    containerClassName={styles.skeleton}
                                    baseColor="#000000"
                                    highlightColor="#3A383D"
                                />
                            )
                        )}

                        {configuratorStore.sittingPositions.length > 0 ? (
                            <Tags
                                type="list"
                                data={configuratorStore.sittingPositions}
                                title={t('sidebarSittingPositionTitle')}
                                active={
                                    configuratorStore.selectedSittingPosition?.name ||
                                    configuratorStore.selectedSittingPosition
                                }
                                onSelect={async (position: SittingPosition) => {
                                    configuratorStore.selectedSittingPosition = position;
                                    window.dataLayer.push({
                                        event: gtmEvents.BIKE_SITTING_POSITION,
                                        sittingPosition: position.name,
                                        category: configuratorStore.bike,
                                        bikeModel: configuratorStore.selectedBike.name,
                                    });
                                    await checkoutStore.sendDataToCustomerIo(
                                        gtmEvents.BIKE_SITTING_POSITION,
                                        position.name,
                                    );
                                }}
                            />
                        ) : (
                            !configuratorStore.loadingEnded && (
                                <Skeleton
                                    count={3}
                                    width={80}
                                    height={30}
                                    containerClassName={styles.skeleton}
                                    baseColor="#000000"
                                    highlightColor="#3A383D"
                                    borderRadius={6}
                                />
                            )
                        )}
                    </div>
                    <div className={styles.preconig_wrapper}>
                        {configuratorStore.preconfigurationsList?.length > 0 ? (
                            <PreConfigurations />
                        ) : (
                            !configuratorStore.loadingEnded && (
                                <Skeleton
                                    count={3}
                                    width={150}
                                    height={144}
                                    containerClassName={styles.skeleton}
                                    baseColor="#000000"
                                    highlightColor="#3A383D"
                                    borderRadius={6}
                                    inline
                                />
                            )
                        )}
                        <SummaryDrawer
                            open={openSummary}
                            onClose={() => {
                                setOpenSummary(false);
                                onExpand(false);
                            }}
                            height={drawerHeight}
                        />
                    </div>
                </div>

                {instalmentsModal && (
                    <InstalmentsModal open={instalmentsModal} onClose={() => setInstalmentsModal(false)} />
                )}
                <div
                    className={clsx(styles.summary, openSummary && styles.hide)}
                    onClick={() => {
                        setOpenSummary(!openSummary);
                        onExpand(true);
                    }}
                >
                    <h6>{t('showSummary')}</h6>
                    <Icon name="arrow_down" className={styles.icon} />
                </div>
                <div
                    className={clsx(
                        styles.configurator_footer,
                        configuratorStore.sidebarWidth > 370 && configuratorStore.sidebarExpanded && styles.smaller,
                    )}
                >
                    <div className={styles.price_from_wrapper}>
                        <p className={styles.total_price}>{t('priceStartingFrom')}</p>
                        <Icon
                            name="question"
                            className={styles.question_mark}
                            onClick={() => setInstalmentsModal(true)}
                        />
                    </div>
                    <h3 className={styles.price}>
                        {configuratorStore.selectedBike?.configuratorPrice?.currency} {parseInt(configPrice)}
                        <span className={styles.per_month}> / {t('month')}</span>
                    </h3>
                    <div className={styles.include_taxes}>
                        <h6>{t('totalPriceIncludeTax')}</h6>
                        <h6>
                            {configuratorStore.selectedBike?.configuratorPrice?.currency}{' '}
                            {localizePrice(configuratorStore.totalPrice)}
                        </h6>
                    </div>
                    <Button
                        type="primaryDark"
                        postIcon="arrow_right"
                        className={styles.button}
                        onClick={() => createScreenshot()}
                        isLoading={loading}
                        loaderClassName={styles.btn_loader}
                        loadingText={t('sidebarLoadingImage')}
                        isDisabled={loading}
                    >
                        {t('sidebarBuyButton')}
                    </Button>
                </div>
            </>
        );
    },
);
