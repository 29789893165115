export const ADDRESS_FIELDS = `
    id
    firstName
    lastName
    companyName
    phone
    streetAddress1
    city
    postalCode
    country {
        code
        country
    }
    countryArea
`;

export const PRICE_FIELDS = `
    currency
    net {
        currency
        amount
    }
    gross {
        currency
        amount
    }
    tax {
        currency
        amount
    }
`;

export const LINES = `
    group
      lines {
        id
        totalPrice{
          net {
            amount
            currency
          }
          gross {
            amount
            currency
          }
        }
        variant{
          id
          name
          product{
            name
            slug
            translation(languageCode: $language) {
                name
            }
            productType{
              name
            }
          }
        }
      }
`;

export const CHECKOUT_FIELDS = `
    id
    email
    token
    phone
    lines{
        id
        totalPrice{
            net {
                amount
                currency
            }
            gross {
                amount
                currency
            }
        }
        variant {
            name
            product {
                id
                name
                category {
                    name
                }
            }
        }
    }
    metadata {
        key
        value
    }
    shippingPrice {
        ${PRICE_FIELDS}
    }
    totalPrice {
        ${PRICE_FIELDS}
    }
    subtotalPrice {
        ${PRICE_FIELDS}
    }
    discount {
        currency
        amount
    }
    shippingAddress{
        ${ADDRESS_FIELDS}
    }
    billingAddress {
        ${ADDRESS_FIELDS}
    }
    user {
        id
        firstName
        lastName
        email
        addresses {
            ${ADDRESS_FIELDS}
        }
    }
    availableCollectionPoints{
        id
        email
        name
        address{
            ${ADDRESS_FIELDS}
        }
        latitude
        longitude
        openingHours{
            id
            weekday
            fromHour
            toHour
        }
    }
    shippingMethods{
        id
        name
        price{
            amount
            currency
        }
        translation(languageCode: $language) {
            name
        }
    }
    deliveryMethod {
        __typename
        ... on Warehouse {
          name
        }
        ... on ShippingMethod {
          name
        }
    }
    availablePaymentGateways {
      id
      name
      config {
        field
        value
      }
    }
    voucherCode
    linesByVariantGroup{
      ${LINES}
    }
    configurationImage
    files {
        id
        fileName
    }
    financingStatus
    financingId
`;
