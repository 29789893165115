import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'shared/components/ui';
import ConfiguratorStore from 'stores/Configurator.store';

type ConfiguratorLoaderProps = {
    progress: number;
    image: string;
};

const TOTAL_FACTS = 3;
const FACTS_INTERVAL = 10000;

export const ConfiguratorLoader: React.FC<ConfiguratorLoaderProps> = ({ progress, image }) => {
    const [currentProgress, setCurrentProgress] = useState(0);
    const store = useContext(ConfiguratorStore);
    const { t } = useTranslation();
    const [fact, setFact] = useState('');

    useEffect(() => {
        setFact(t('configuratorLoaderText'));
        let i = 0;
        const interval = setInterval(() => {
            i = i === TOTAL_FACTS ? 1 : i + 1;
            setFact(t(`loaderFacts.fact${i}`));
        }, FACTS_INTERVAL);

        return () => clearInterval(interval);
    }, [t]);

    useEffect(() => {
        store.initialLoading = true;
        const updateProgress = () => {
            setCurrentProgress(currentProgress + 1);
        };
        const interval = setInterval(updateProgress, 1000);

        if (progress >= 10) {
            clearInterval(interval);
            setCurrentProgress(progress);
        }
        if (progress >= 100) {
            store.initialLoading = false;
        }

        return () => clearInterval(interval);
    }, [progress, currentProgress, store]);

    return (
        <>
            <div id="loadingScreen" className="bike3d__loading-screen">
                <div className="loading-screen__items d-col">
                    <div className="image-wrapper">
                        {image && <img src={`${image}?trim=color&trim-tol=50&w=300&h=160`} alt="Bike" />}
                    </div>
                    <h2>{t('configuratorLoaderTitle')}</h2>
                    <p className="loading-text">{fact}</p>
                    <div className="loading-slider">
                        <div className="progress" style={{ width: `${currentProgress.toFixed(0)}%` }}></div>
                    </div>
                </div>
            </div>
            <Portal>
                <div id="config-backdrop"></div>
            </Portal>
        </>
    );
};
