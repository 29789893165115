import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { gtmEvents } from '../../../../shared/types/gtmEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { Color } from '../Color/Color';
import { ColorPickerPopup } from '../ColorPickerPopup/ColorPickerPopup';

import styles from './ColorSection.module.scss';

// const hexColorRegex = /^#([0-9a-f]{3}){1,2}$/i;

export const ColorSection = observer(() => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();
    const [localActiveColor, setLocalActiveColor] = useState('');
    const [hasCustomColor, setHasCustomColor] = useState(store.hasSelectedColors);

    useEffect(() => {
        if (store.hasSelectedColors) {
            setHasCustomColor(true);
        }
    }, [store.hasSelectedColors]);

    const setColor = async (color: any) => {
        store.selectedBikeVariant = {
            id: color.id,
            weight: color.weight,
            color: color.value,
            colorName: color.colorName,
        };
        setLocalActiveColor(color.id);
        setHasCustomColor(false);
        store.selectedColors = [];

        window.dataLayer.push({
            event: gtmEvents.BIKE_COLOR,
            category: store.bike,
            bikeModel: store.selectedBike.name,
            color: color.colorName,
            hexColor: color.value,
        });

        await checkoutStore.sendDataToCustomerIo(gtmEvents.BIKE_COLOR, color.colorName);
    };

    if (!store.selectedBike?.variants) {
        return (
            <Skeleton
                count={3}
                circle={true}
                width={40}
                height={40}
                containerClassName={styles.skeleton_wrap}
                baseColor="#000000"
                highlightColor="#3A383D"
            />
        );
    }

    return (
        <div className={styles.color_picker}>
            <p className={clsx(styles.title, store.sidebarWidth > 370 && store.sidebarExpanded && styles.smaller)}>
                {t('sidebarFrameColorTitle')}
            </p>
            <div className={styles.color_list}>
                {store.selectedBike?.variants.map((variant: any) => {
                    return (
                        <Color
                            key={variant.id}
                            color={{
                                id: variant?.id,
                                value: variant.attributes[0]?.values[0]?.value || '#CCCCCC',
                                weight: variant?.weight?.value,
                                colorName: variant.attributes[0]?.values[0]?.name,
                            }}
                            colorSelected={!hasCustomColor && store.selectedBikeVariant?.id === variant?.id}
                            setSelectedColor={(color: any) => setColor(color)}
                            smallerColor={store.sidebarWidth > 370 && store.sidebarExpanded}
                        />
                    );
                })}
                {store.selectedBike?.isCustomColorActive && (
                    <ColorPickerPopup
                        active={localActiveColor === 'color picker'}
                        clicked={name => {
                            setLocalActiveColor(name);
                            setHasCustomColor(true);
                        }}
                        hasCustomColor={hasCustomColor}
                        onClose={() => {
                            if (store.selectedColors.length !== 0) {
                                setLocalActiveColor('');
                            } else {
                                setLocalActiveColor(store.selectedBikeVariant.id);
                                setHasCustomColor(false);
                            }
                        }}
                        smaller={store.sidebarWidth > 370 && store.sidebarExpanded}
                    />
                )}
            </div>
        </div>
    );
});
