import { ADDRESS_VALIDATION_RULES } from 'api/queries/checkout';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { Button, ErrorMessage, Input, Label, Select } from 'shared/components/ui';
import { PHONE_REGEX } from 'shared/helpers/validators';
import { apolloClient } from 'shared/lib/apollo';
import { AddressInputType, CountryType } from 'shared/types/checkout';

import styles from './AddressForm.module.scss';

type AddressFormProps = {
    initialData?: any;
    isLoading?: boolean;
    isEdit?: boolean;
    onSubmit: any;
    countries?: any;
    phone?: string | null;
};

export const AddressForm: React.FC<AddressFormProps> = ({
    initialData,
    isLoading,
    isEdit,
    onSubmit,
    countries,
    phone,
}) => {
    const {
        register,
        control,
        watch,
        setValue,
        handleSubmit,
        // reset,
        formState: { errors },
    } = useForm<AddressInputType>({
        defaultValues: initialData ? initialData : { country: 'CH' },
    });
    const { t } = useTranslation();

    // set default country to be Switzerland
    const defaultCountry = countries.find((c: any) => c.code === 'CH');
    const [countryAreas, setCountryAreas] = useState<{ value: string; label: string }[]>([]);
    const selectedCountry = watch('country');
    const selectedCountryArea = watch('countryArea');

    useEffect(() => {
        if (phone && !initialData?.phone) setValue('phone', phone);
    }, [phone, setValue, initialData?.phone]);

    useEffect(() => {
        const getCountryAreas = async (countryCode?: string) => {
            try {
                setCountryAreas([]);
                if (!countryCode) return;
                const resp = await apolloClient.query({ query: ADDRESS_VALIDATION_RULES, variables: { countryCode } });
                const countryAreas = resp.data.addressValidationRules.countryAreaChoices.map((c: any) => {
                    return {
                        value: c.raw,
                        label: c.verbose,
                    };
                });

                setCountryAreas(countryAreas);
                if (selectedCountryArea !== initialData?.countryArea) {
                    setValue('countryArea', countryAreas[0]?.value ?? '');
                }
            } catch (error) {
                console.error(error);
            }
        };

        getCountryAreas(selectedCountry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountry]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <Input
                className={styles.input}
                labelWrapperClassName={styles.labelWrapper}
                label={t('companyInput')}
                placeholder={t('companyPlaceholder')}
                {...register('companyName')}
            />

            <Input
                className={styles.input}
                labelWrapperClassName={styles.labelWrapper}
                label={t('firstNameInput')}
                placeholder={t('firstNamePlaceholder')}
                error={errors.firstName?.message}
                {...register('firstName', { required: t('fieldRequired') })}
            />

            <Input
                className={styles.input}
                labelWrapperClassName={styles.labelWrapper}
                label={t('lastNameInput')}
                placeholder={t('lastNamePlaceholder')}
                error={errors.lastName?.message}
                {...register('lastName', { required: t('fieldRequired') })}
            />

            <Controller
                name="country"
                control={control}
                rules={{ required: t('fieldRequired') }}
                render={({ field: { name, onChange } }) => (
                    <Select<CountryType>
                        label={t('countryInput')}
                        // placeholder={t('countryPlaceholder')}
                        options={countries}
                        name={name}
                        defaultValue={{ country: defaultCountry.country, code: defaultCountry.code }}
                        getOptionLabel={data => data.country}
                        getOptionValue={data => data.code}
                        onChange={data => {
                            onChange(data?.code);
                            setValue('countryArea', '');
                        }}
                        error={errors.country?.message}
                    />
                )}
            />

            {countryAreas.length > 0 && (
                <Controller
                    name="countryArea"
                    control={control}
                    render={({ field: { name, value, onChange } }) => {
                        return (
                            <Select
                                label={t('countryAreaInput')}
                                placeholder={t('countryAreaPlaceholder')}
                                options={countryAreas}
                                name={name}
                                value={countryAreas.find(ca => ca.value === value) ?? countryAreas[0] ?? ''}
                                isDisabled={!countryAreas.length}
                                onChange={data => onChange(data?.value)}
                                error={errors.countryArea?.message}
                            />
                        );
                    }}
                />
            )}

            <Input
                className={styles.input}
                labelWrapperClassName={styles.labelWrapper}
                label={t('streetAddressInput')}
                placeholder={t('streetAddressPlaceholder')}
                error={errors.streetAddress1?.message}
                {...register('streetAddress1', {
                    required: t('fieldRequired'),
                    // // Street address must end in space and then number
                    // pattern: {
                    //     value: /\s\d{1,}$/gm,
                    //     message: t('streetMustHaveNumber'),
                    // },
                })}
            />

            <div className={styles.cityWrapper}>
                <Input
                    type="number"
                    className={clsx(styles.input, styles.postalCode)}
                    labelWrapperClassName={styles.labelWrapper}
                    label={t('postcodeInput')}
                    placeholder={t('postcodePlaceholder')}
                    error={errors.postalCode?.message}
                    {...register('postalCode', { required: t('fieldRequired') })}
                />

                <Input
                    className={clsx(styles.input, styles.city)}
                    labelWrapperClassName={styles.labelWrapper}
                    label={t('cityInput')}
                    placeholder={t('cityPlaceholder')}
                    error={errors.city?.message}
                    {...register('city', { required: t('fieldRequired') })}
                />
            </div>

            <div>
                <Label title={t('phoneNumberTitle')} tooltipText={t('phoneNumberTooltip')} />
                <Controller
                    name="phone"
                    control={control}
                    rules={{
                        required: t('fieldRequired'),
                        pattern: {
                            value: PHONE_REGEX,
                            message: t('phoneRequiresDigits'),
                        },
                    }}
                    render={({ field: { value, onChange } }) => (
                        <PhoneInput
                            placeholder={t('phonePlaceholder')}
                            value={value}
                            onChange={onChange}
                            country={'ch'}
                            preferredCountries={['ch', 'de', 'at']}
                        />
                    )}
                />

                {errors.phone?.message && <ErrorMessage message={errors.phone?.message} className={styles.error} />}
            </div>

            <Button htmlType="submit" className={styles.modalButton} isDisabled={isLoading} isLoading={isLoading}>
                {isEdit ? t('editAddressButton') : t('saveAddressButton')}
            </Button>
        </form>
    );
};
