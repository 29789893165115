import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { CookieHelper } from '../../../helpers/cookie';

import { resetTrackScrollDepth, sendGtmEvent, trackScrollDepth } from './gtmHelpers';

export const GoogleTagManagerInitializer = () => {
    const { pathname } = useLocation();
    const cookieConsent = CookieHelper.get('cookie_consent');
    const ad_storage = CookieHelper.get('ad_storage');
    const analytics_storage = CookieHelper.get('analytics_storage');
    const personalization_storage = CookieHelper.get('personalization_storage');

    useEffect(() => {
        trackScrollDepth();

        return () => {
            resetTrackScrollDepth();
        };
    }, [pathname]);

    // Track page views
    useEffect(() => {
        setTimeout(() => {
            sendGtmEvent({
                action: 'page_view',
                category: 'Engagement',
            });
        }, 250);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <script>
                    {`
                    // Define dataLayer and the gtag function.
                         window.dataLayer = window.dataLayer || [];
                         function gtag(){dataLayer.push(arguments);}
                         
                         // Set default consent parameters
                         if(${cookieConsent === 'accepted'}){
                            gtag('consent', 'default', {
                                'ad_storage': ${ad_storage === 'granted'} ? 'granted' : 'denied',
                                'analytics_storage': ${analytics_storage === 'granted'} ? 'granted' : 'denied',
                                'functionality_storage': 'granted',
                                'personalization_storage': ${
                                    personalization_storage === 'granted'
                                } ? 'granted' : 'denied',
                                'security_storage': 'denied',
                                'ad_user_data': 'denied',
                                'ad_personalization': 'denied'
                            });
                        } else {
                            gtag('consent', 'default', {
                                'ad_storage': 'denied',
                                'analytics_storage': 'denied',
                                'functionality_storage': 'granted',
                                'personalization_storage': 'denied',
                                'security_storage': 'denied',
                                'ad_user_data': 'denied',
                                'ad_personalization': 'denied'
                            });
                        }
                    `}
                </script>
            </Helmet>

            {/* Google Tag Manager Init */}
            <Helmet>
                <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_GTM}');
                    `}
                </script>
            </Helmet>
        </>
    );
};
