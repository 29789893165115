import clsx from 'clsx';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { CheckoutMetadataKey } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button, ErrorMessage, Icon, Input, Select, Textarea } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';
import 'react-datepicker/dist/react-datepicker.css';

import { Accordion } from '../Accordion/Accordion';

import { CheckoutFileUpload } from './_components/CheckoutFileUpload';
import { ExpertPayment } from './_components/ExpertPayment/ExpoertPayment';
import styles from './ExpertCheckout.module.scss';

interface ExpertCheckoutFormType {
    officialComments: string;
    internalComments: string;
    options: string;
    description: string;
    location: string;
    discountPercentage: number;
    discountAmountOne: number;
    salesperson: string;
    point_of_sale: string;
    order_type: string;
    deliveryDate: Date;
    discount_code: string;
    discount_type: string;
    deposit: number;
}

export const ExpertCheckout = observer(() => {
    const checkoutStore = useContext(CheckoutStore);

    const {
        register,
        control,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        getValues,
        getFieldState,
        setValue,
        reset,
        formState: { errors },
    } = useForm<ExpertCheckoutFormType>({
        // mode: 'onChange',
        reValidateMode: 'onSubmit',
    });
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOffer, setIsLoadingOffer] = useState(false);
    const [isLoadingOrder, setIsLoadingOrder] = useState(false);
    const [chargeClient, setChargeClient] = useState(false);
    const [dropdownFields, setDropdownFields] = useState<any>();
    const [isVoucherLoading, setIsVoucherLoading] = useState(false);

    const [isTradeInAccordionExpanded, setIsTradeInAccordionExpanded] = useState(false);
    const [isDiscountAccordionExpanded, setIsDiscountAccordionExpanded] = useState(false);

    const [defaultSalesPerson, setDefaultSalesPerson] = useState<any>(null);
    const [defaultOrderType, setDefaultOrderType] = useState<any>(null);
    const [defaultPOS, setDefaultPOS] = useState<any>(null);

    const checkoutPrice = checkoutStore.data?.totalPrice?.gross?.amount;
    const discountTypes = [
        { label: 'CHF', value: 'CHF' },
        { label: '%', value: '%' },
    ];
    const formIsValid = !!getFieldState('deposit').error || !!getFieldState('discount_code').error;

    useEffect(() => {
        const fetchDropdownFields = async () => {
            try {
                const dropdownFields = await checkoutStore.getExpertCheckoutDropdownFields();
                setDropdownFields(dropdownFields);
            } catch (error) {
                console.error(error);
            }
        };

        async function getStore() {
            const persistedData = await checkoutStore.getStoredData();
            if (persistedData) {
                persistedData.expertCheckoutData.deliveryDate = dayjs(
                    persistedData?.expertCheckoutData.deliveryDate,
                ).toDate();
                if (persistedData.expertCheckoutData.salesperson) {
                    setValue('salesperson', persistedData.expertCheckoutData.salesperson);
                }
                reset({ ...persistedData.expertCheckoutData });
            }
        }

        fetchDropdownFields();
        getStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const salespersonDropdown = useMemo(() => {
        const sales = dropdownFields?.salesperson?.map((data: any) => {
            return { label: data.name, value: data.name };
        });
        if (checkoutStore.expertCheckoutData.salesperson) {
            setDefaultSalesPerson({
                label: checkoutStore.expertCheckoutData.salesperson,
                value: checkoutStore.expertCheckoutData.salesperson,
            });
        }

        return sales;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownFields]);

    const pointOfSaleDropdown = useMemo(() => {
        const pointOfSales = dropdownFields?.point_of_sale?.map((data: any) => {
            return { label: data.name, value: data.name };
        });

        if (checkoutStore.expertCheckoutData.point_of_sale) {
            setDefaultPOS({
                label: checkoutStore.expertCheckoutData.point_of_sale,
                value: checkoutStore.expertCheckoutData.point_of_sale,
            });
        }

        return pointOfSales;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownFields]);

    const orderTypeDropdown = useMemo(() => {
        const orderTypes = dropdownFields?.order_type?.map((data: any) => {
            return { label: data.name, value: data.name };
        });

        if (checkoutStore.expertCheckoutData.order_type) {
            setDefaultOrderType({
                label: checkoutStore.expertCheckoutData.order_type,
                value: checkoutStore.expertCheckoutData.order_type,
            });
        }

        return orderTypes;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownFields]);

    useEffect(() => {
        const subscription = watch((formData, { name }) => {
            if (!name) return;
            if (name === 'discountPercentage') {
                checkoutStore.expertCheckoutDiscountPercentage = Number(formData[name]);
                checkoutStore.expertCheckoutData.discountPercentage = Number(formData[name]) ?? 0;
            }
            if (name === 'discountAmountOne') {
                checkoutStore.expertCheckoutDiscountAmountOne = Number(formData[name]);
                checkoutStore.expertCheckoutData.discountAmountOne = Number(formData[name]) ?? 0;
            }
            if (name === 'deposit') {
                if (checkoutPrice && Number(formData[name]) >= checkoutPrice) {
                    checkoutStore.expertCheckoutDepositAmount = checkoutPrice;
                    checkoutStore.expertCheckoutData.deposit = checkoutPrice;
                    setError('deposit', { message: 'Die Anzahlung darf nicht höher sein als der Gesamtpreis' });
                } else {
                    checkoutStore.expertCheckoutDepositAmount = Number(formData[name]);
                    checkoutStore.expertCheckoutData.deposit = Number(formData[name]);
                    clearErrors('deposit');
                }
            }
            if (name === 'discount_code' || name === 'discount_type') {
                if (formData['discount_type'] === '%' && Number(formData['discount_code']) > 100) {
                    setError('discount_code', { message: 'Der Prozentsatz darf nicht größer als 100 sein' });
                } else {
                    clearErrors('discount_code');
                }
            }
            if (name === 'deliveryDate') {
                checkoutStore.expertCheckoutData.deliveryDate = dayjs(formData[name]).toDate();
            }
            checkoutStore.expertCheckoutData[name] = formData[name] ?? '';
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const submit = async (data: ExpertCheckoutFormType, isOffer = true, unpaid = false) => {
        data.deliveryDate = dayjs(data.deliveryDate).format('YYYY-MM-DD') as any;
        // data.amount_to_charge_percentage = checkoutStore.prepaymentPercentage.toString();
        try {
            if (isOffer) setIsLoadingOffer(true);

            if (!isOffer) setIsLoadingOrder(true);
            const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
                gateway => gateway.id === 'mirumee.payments.dummy',
            );
            if (expertCheckoutPaymentGateway) {
                await checkoutStore.createPayment(expertCheckoutPaymentGateway.id, isOffer, unpaid);
            }
            const metadata = [{ key: CheckoutMetadataKey.EXPERT_CHECKOUT, value: JSON.stringify(data) }];
            await checkoutStore.updateCheckoutMetadata(metadata);
            await checkoutStore.checkoutComplete(checkoutStore.data?.id, isOffer);
            await checkoutStore.clearStoredDate();

            navigate(AppRoutes.orderStatus.replace(':id', checkoutStore.data?.id ?? ''));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setIsLoadingOrder(false);
            setIsLoadingOffer(false);
        }
    };

    const submitPay = async (data: ExpertCheckoutFormType) => {
        data.deliveryDate = dayjs(data.deliveryDate).format('YYYY-MM-DD') as any;
        // data.amount_to_charge_percentage = checkoutStore.prepaymentPercentage.toString();
        try {
            setIsLoading(true);
            const metadata = [{ key: CheckoutMetadataKey.EXPERT_CHECKOUT, value: JSON.stringify(data) }];
            await checkoutStore.updateCheckoutMetadata(metadata);
            setChargeClient(true);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    // const updatePrepayment = async (value: any) => {
    //     checkoutStore.prepaymentPercentage = value.value;
    //
    //     try {
    //         const metadata = [{ key: CheckoutMetadataKey.EXPERT_CHECKOUT_CHARGE_PERCENTAGE, value: value.value }];
    //         await checkoutStore.updateCheckoutMetadata(metadata);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    const applyDiscount = async () => {
        try {
            setIsVoucherLoading(true);
            const amount = getValues('discount_code') ? parseInt(getValues('discount_code')) : 0;
            const isPercentage = getValues('discount_type') === '%';
            await checkoutStore.createCustomVoucher(amount, isPercentage);
        } catch (e) {
            console.error(e);
        } finally {
            setIsVoucherLoading(false);
        }
    };

    return (
        <div className={styles.root}>
            <h3>{t('expertCheckout.comments')}</h3>
            <Textarea
                label={t('expertCheckout.officialComments')}
                placeholder={t('expertCheckout.officialComments')}
                textareaClassName={styles.input}
                {...register('officialComments')}
            />
            <Textarea
                label={t('expertCheckout.internalComments')}
                placeholder={t('expertCheckout.internalComments')}
                textareaClassName={styles.input}
                {...register('internalComments')}
            />

            <h3>{t('expertCheckout.cost')}</h3>
            <Textarea
                label={t('expertCheckout.options')}
                placeholder={t('expertCheckout.options')}
                textareaClassName={styles.input}
                {...register('options')}
            />

            <Accordion
                expanded={isTradeInAccordionExpanded}
                className={clsx(styles.accordion, isTradeInAccordionExpanded && styles.expanded)}
                titleComponent={
                    <h4 onClick={() => setIsTradeInAccordionExpanded(!isTradeInAccordionExpanded)}>
                        {t('bikeReplacement')} <Icon name="arrow_down" />
                    </h4>
                }
                contentComponent={
                    <>
                        <Input
                            label={t('expertCheckout.description')}
                            placeholder={t('expertCheckout.description')}
                            inputClassName={styles.input}
                            {...register('description')}
                        />
                        <div className={styles.row}>
                            <Input
                                label={t('expertCheckout.location')}
                                placeholder={t('expertCheckout.location')}
                                inputClassName={styles.input}
                                {...register('location')}
                            />
                            <Input
                                label={t('expertCheckout.tradeInValue')}
                                placeholder="0"
                                type="number"
                                suffix={checkoutStore.data?.totalPrice?.currency}
                                inputClassName={styles.input}
                                {...register('discountAmountOne')}
                            />
                        </div>
                    </>
                }
            />

            <Accordion
                expanded={isDiscountAccordionExpanded}
                className={clsx(styles.accordion, isDiscountAccordionExpanded && styles.expanded)}
                titleComponent={
                    <h4 onClick={() => setIsDiscountAccordionExpanded(!isDiscountAccordionExpanded)}>
                        {t('discountExpert')} <Icon name="arrow_down" />
                    </h4>
                }
                contentComponent={
                    <div className={styles.discount_wrapper}>
                        <div className={styles.input_discount_wrapper}>
                            <div className={styles.fields}>
                                <Input
                                    {...register('discount_code')}
                                    label={t('expertCheckout.discountAmount')}
                                    placeholder="0"
                                    className={styles.input_discount}
                                    type="number"
                                    inputMode="numeric"
                                    onKeyDown={evt =>
                                        ['e', 'E', '+', '-', '.', ','].includes(evt.key) && evt.preventDefault()
                                    }
                                    onWheel={(e: any) => e.target.blur()}
                                />
                                <div className={styles.discount_type}>
                                    <Controller
                                        name="discount_type"
                                        control={control}
                                        render={({ field: { name, onChange } }) => {
                                            return (
                                                <Select
                                                    options={discountTypes}
                                                    name={name}
                                                    defaultValue={{ label: 'CHF', value: 'CHF' }}
                                                    onChange={(data: any) => {
                                                        onChange(data?.value);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>

                            {errors?.discount_code?.message && (
                                <ErrorMessage message={errors?.discount_code?.message} />
                            )}
                        </div>

                        <Button
                            onClick={applyDiscount}
                            className={clsx(
                                styles.apply_discount_btn,
                                errors?.discount_code?.message && styles.has_error,
                            )}
                            isLoading={isVoucherLoading}
                            isDisabled={!!getFieldState('discount_code').error}
                        >
                            {t('expertCheckout.discountApply')}
                        </Button>
                    </div>
                }
            />

            <div className={styles.deposit_box}>
                <Input
                    {...register('deposit')}
                    label={t('expertCheckout.deposit')}
                    placeholder="0"
                    inputClassName={styles.deposit_input}
                    suffix={checkoutStore.data?.totalPrice?.currency}
                    error={errors?.deposit?.message}
                    type="number"
                    inputMode="numeric"
                    onKeyDown={evt => ['e', 'E', '+', '-', '.', ','].includes(evt.key) && evt.preventDefault()}
                    errorClassName={styles.error}
                    onWheel={(e: any) => e.target.blur()}
                />
            </div>

            <h3>{t('expertCheckout.conclusion')}</h3>
            <div className={styles.row}>
                <Controller
                    name="salesperson"
                    control={control}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                label={t('expertCheckout.salesperson')}
                                placeholder={t('expertCheckout.salesperson')}
                                labelWrapperClassName={styles.selectLabelWrapper}
                                options={salespersonDropdown}
                                name={name}
                                onChange={(data: any) => onChange(data?.value)}
                                defaultValue={defaultSalesPerson}
                            />
                        );
                    }}
                />

                <Controller
                    name="point_of_sale"
                    control={control}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                label={t('expertCheckout.pointOfSale')}
                                placeholder={t('expertCheckout.pointOfSale')}
                                labelWrapperClassName={styles.selectLabelWrapper}
                                options={pointOfSaleDropdown}
                                name={name}
                                onChange={(data: any) => onChange(data?.value)}
                                defaultValue={defaultPOS}
                            />
                        );
                    }}
                />
            </div>

            <div className={styles.row}>
                <Controller
                    name="order_type"
                    control={control}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                label={t('expertCheckout.orderType')}
                                placeholder={t('expertCheckout.orderType')}
                                labelWrapperClassName={styles.selectLabelWrapper}
                                options={orderTypeDropdown}
                                name={name}
                                onChange={(data: any) => onChange(data?.value)}
                                defaultValue={defaultOrderType}
                            />
                        );
                    }}
                />

                {/* <Input
                    label="Lieferdatum"
                    placeholder="Gewünschtes Lieferdatum"
                    inputClassName={styles.input}
                    {...register('deliveryDate')}
                /> */}
                <div>
                    <Controller
                        name="deliveryDate"
                        control={control}
                        render={({ field: { name, onChange } }) => {
                            return (
                                <DatePicker
                                    selected={getValues('deliveryDate') as Date}
                                    onChange={onChange}
                                    name={name}
                                    dateFormat="dd MMM yyyy"
                                    minDate={dayjs().toDate()}
                                    customInput={<Input inputClassName={styles.input} label="Lieferdatum" />}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <CheckoutFileUpload />

            <div className={styles.actions}>
                {!chargeClient && (
                    <Button
                        className={styles.button}
                        isLoading={isLoading}
                        onClick={handleSubmit(submitPay)}
                        isDisabled={formIsValid}
                    >
                        {t('expertCheckout.continueAndPay')}
                    </Button>
                )}
                <Button
                    className={styles.button}
                    onClick={handleSubmit(data => submit(data, false, true))}
                    type="secondary"
                    isLoading={isLoadingOrder}
                    isDisabled={formIsValid}
                >
                    {t('expertCheckout.orderAndFinish')}
                </Button>
                <Button
                    className={styles.button}
                    type="secondary"
                    onClick={handleSubmit(data => submit(data, true, false))}
                    isLoading={isLoadingOffer}
                    isDisabled={formIsValid}
                >
                    {t('create_offer')}
                </Button>
            </div>

            {chargeClient && <ExpertPayment />}

            {/*{chargeClient && (*/}
            {/*    <div className={styles.customCharge}>*/}
            {/*        <hr />*/}
            {/*        <h3>Zahlung</h3>*/}
            {/*        <div className={styles.amount}>*/}
            {/*            <Select*/}
            {/*                label="Amount to charge"*/}
            {/*                options={percentages}*/}
            {/*                onChange={updatePrepayment}*/}
            {/*                defaultValue={percentages.find(p => p.value === 30)}*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*        <StripePayment />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
});
